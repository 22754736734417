import {
  AfterViewInit,
  Component,
  OnDestroy,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  input,
  ElementRef,
} from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  TableColumn,
  DataTablePaginate,
  NgClassTableTd,
  ConditionsEvaluateTable,
  OperatorsLogical,
} from './tablecolumn.model';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Observable } from 'rxjs';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SubSink } from 'subsink';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { DataPropertyGetterPipe } from '@core/pipe/data-property-getter.pipe';
import { FormatDateCustomPipe } from '@core/pipe/format-date-custom.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PaginationInternationalizationTable } from './pagination.internationalization';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BooleanPipe, PipeDaysWeekPipe } from '@shared/pipe';

@Component({
  selector: 'ic-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    ReactiveFormsModule,
    DataPropertyGetterPipe,
    FormatDateCustomPipe,
    MatTooltipModule,
    NgxMaskPipe,
    MatSortModule,
    PipeDaysWeekPipe,
    BooleanPipe,
    MatPaginatorModule,
  ],
  providers: [
    LiveAnnouncer,
    provideNgxMask(),
    { provide: MatPaginatorIntl, useClass: PaginationInternationalizationTable },
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableDataComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  public tableDataSource = new MatTableDataSource([]);
  public displayedColumns: string[];

  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild('overflowauto', { read: ElementRef }) overflowauto;

  settingsColorTD = input<NgClassTableTd[]>([]);

  placeholder = input<string>('');
  color_td = input<string>('test_color');
  notRecord = input<string>('No existen registros...');
  isPageable = input(false);
  isSortable = input(false);
  isFilterable = input(false);
  tableColumns = input<TableColumn[]>([]);
  paginationSizes = input<number[]>([25]);
  defaultPageSize = input(this.paginationSizes[25]);
  $eventRefreshData = input<Observable<any>>();
  viewScroll = input(false);

  // this property needs to have a setter, to dynamically get changes from parent component
  @Input() set setDataTable(data: any | DataTablePaginate) {
    this.setTableDataSource(data);
  }

  @Output() rowAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToDetails: EventEmitter<any> = new EventEmitter<any>();

  /****Eventos exclusivos para los actions *****/
  @Output() eventDeleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventEditAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventRefundAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventDownAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventUpAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventPrintAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventCustomAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventInputSerach: EventEmitter<any> = new EventEmitter<any>();
  @Output() eventPagination: EventEmitter<MatPaginator> = new EventEmitter<MatPaginator>();

  searchField = new FormControl('');
  pageIndex = 0;

  expandedElement: any;

  constructor(private _liveAnnouncer: LiveAnnouncer) {}
  ngOnInit(): void {
    this.setColumns(this.tableColumns());

    if (this.$eventRefreshData()) {
      this.subs.add(
        this.$eventRefreshData().subscribe((data) => {
          this.setTableDataSource(data);
        })
      );
    }
  }

  // we need this, in order to make pagination work with *ngIf
  ngAfterViewInit(): void {
    if (this.isPageable) {
      this.tableDataSource.paginator = this.matPaginator;
      this.matPaginator.pageSizeOptions = this.paginationSizes();
    }
  }

  setColumns(columns: TableColumn[]) {
    const columnNames = columns
      .filter((e) => e.notVisible !== true)
      .map((tableColumn: TableColumn) => tableColumn.dataKey);

    this.displayedColumns = columnNames;
  }

  pageEvent($event) {
    this.eventPagination.emit($event);
    this.pageIndex = $event.pageIndex;
  }

  setTableDataSource(data: DataTablePaginate) {
    if (!data) return;

    if (data.updateColumns) this.setColumns(data.updateColumns);

    this.tableDataSource = new MatTableDataSource<any>(data.data);
    this.tableDataSource.paginator = this.matPaginator;
    if (data.length)
      setTimeout(() => {
        this.tableDataSource.paginator.length = data.length;
        this.tableDataSource.paginator.pageIndex = this.pageIndex;

        if (data.resetPage) this.tableDataSource.paginator.pageIndex = 0;
      });

    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.tableDataSource.paginator) {
      this.tableDataSource.paginator.firstPage();
    }
  }

  onSubmitSearch() {
    if (this.searchField.valid) this.eventInputSerach.emit(this.searchField.value);
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  emitGoToDetails(data: any) {
    if (this.goToDetails) this.goToDetails.emit(data);
  }

  /****Eventos exclusivos para los actions *****/
  emitEventDelete(data) {
    this.eventDeleteAction.emit(data);
  }

  emitEventEdit(data) {
    this.eventEditAction.emit(data);
  }

  emitEventRefund(data) {
    this.eventRefundAction.emit(data);
  }

  emitEventDown(data) {
    this.eventDownAction.emit(data);
  }

  emitEventActive(data) {
    this.eventUpAction.emit(data);
  }

  emitEventPrint(data) {
    this.eventPrintAction.emit(data);
  }

  emitEventCustom($event, valueEvent) {
    if (valueEvent) this.eventCustomAction.emit({ item: $event, valueEvent });
    else this.eventCustomAction.emit($event.item);
  }

  // this method is used to sort the table data
  announceSortChange(sortParameters: Sort) {
    if (sortParameters.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortParameters.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  conditionColorTd(row: any) {
    const ngClass: any = {};
    const settings = this.settingsColorTD();

    if (!settings) return ngClass;

    settings.forEach((setting) => {
      if (setting.conditions) {
        const result = this.evaluateConditionComparative(setting.conditions, row);
        ngClass[setting.class] = result;
      }
    });

    return ngClass;
  }

  evaluateConditionComparative(conditions: ConditionsEvaluateTable, row: any) {
    if (!conditions || conditions.length === 0) return true;

    let expresionEval = conditions
      .map((item) => {
        const isOperatorLogical = (x: any): x is OperatorsLogical => ['&&', '||'].includes(x);

        if (isOperatorLogical(item)) {
          return ` ${item}`;
        } else {
          const a = item.a.campoRow ? (row[item.a.campoRow] ?? undefined) : item.a.value;
          const b = item.b.campoRow ? (row[item.b.campoRow] ?? undefined) : item.b.value;
          return ` '${a}' ${item.operator} '${b}'`;
        }
      })
      .join('');
    return Function(`return ${expresionEval}`)();
  }

  eventScroll(direction: 'left' | 'right') {
    if (direction === 'left')
      this.overflowauto.nativeElement.scrollTo({
        left: this.overflowauto.nativeElement.scrollLeft - 150,
        behavior: 'smooth',
      });
    else
      this.overflowauto.nativeElement.scrollTo({
        left: this.overflowauto.nativeElement.scrollLeft + 150,
        behavior: 'smooth',
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
