<!-- Filter -->
@if (isFilterable) {
  <div class="mx-auto my-4 flex w-full md:w-3/4">
    <label for="voice-search" class="sr-only">Search</label>
    <div class="relative w-full">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          aria-hidden="true"
          class="h-5 w-5 text-gray-500 dark:text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"></path>
        </svg>
      </div>
      <input
        type="text"
        class="ic-input-search-table"
        (keyup)="applyFilter($event)"
        [placeholder]="placeholder()"
        (keydown.enter)="onSubmitSearch()"
        [formControl]="searchField" />
    </div>
    <button type="submit" class="ic-button-search" [disabled]="!searchField.valid" (click)="onSubmitSearch()">
      <svg
        aria-hidden="true"
        class="-ml-1 mr-2 h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
      </svg>
      <span class="hidden sm:inline"> Buscar </span>
    </button>
  </div>
}

@if (viewScroll()) {
  <div class="flex justify-between">
    <button type="button" class="ic-action-edit" (click)="eventScroll('left')">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <button type="button" class="ic-action-edit" (click)="eventScroll('right')">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
}

<div class="w-full overflow-auto" #overflowauto>
  <!-- Table -->
  <table
    mat-table
    [dataSource]="tableDataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="w-full border-separate border-spacing-2 bg-ica-textsidebar">
    @for (tableColumn of tableColumns(); track tableColumn; let idx = $index) {
      <ng-container [matColumnDef]="tableColumn.dataKey">
        <!-- if sortable column header -->
        @if (tableColumn.isSortable) {
          <th
            mat-header-cell
            fxFlex
            *matHeaderCellDef
            mat-sort-header
            [sortActionDescription]="'Sort by ' + tableColumn.dataKey"
            [arrowPosition]="tableColumn.position === 'right' ? 'before' : 'after'">
            {{ tableColumn.name }}
          </th>
        } @else {
          <th mat-header-cell fxFlex *matHeaderCellDef [class.text-right]="tableColumn.position === 'right'">
            {{ tableColumn.name }}
          </th>
        }
        <!-- else not sortable -->
        <!-- column data -->
        @if (tableColumn.typeElement === 'actions') {
          <td mat-cell *matCellDef="let element" [class.text-right]="tableColumn.position === 'right'" stickyEnd>
            <div class="flex flex-wrap items-center justify-center gap-1">
              @for (item of tableColumn.actions; track item) {
                @if (evaluateConditionComparative(item.conditions, element)) {
                  @switch (item.action) {
                    @case ('edit') {
                      <button class="ic-action-edit" (click)="emitEventEdit(element)" [matTooltip]="item.tooltip">
                        <mat-icon class="">{{ item.icon ?? 'keyboard_arrow_up' }}</mat-icon>
                      </button>
                    }
                    @case ('delete') {
                      <button class="ic-action-delete" (click)="emitEventDelete(element)" [matTooltip]="item.tooltip">
                        <mat-icon class="">{{ item.icon ?? 'delete_forever' }}</mat-icon>
                      </button>
                    }
                    @case ('refund') {
                      <button class="ic-action-third" (click)="emitEventRefund(element)" [matTooltip]="item.tooltip">
                        <mat-icon class="">money_off</mat-icon>
                      </button>
                    }
                    @case ('down') {
                      <button class="ic-action-down" (click)="emitEventDown(element)" [matTooltip]="item.tooltip">
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </button>
                    }
                    @case ('active') {
                      <button class="ic-action-edit" (click)="emitEventActive(element)" [matTooltip]="item.tooltip">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                      </button>
                    }
                    @case ('print') {
                      <button class="ic-action-third" (click)="emitEventPrint(element)" [matTooltip]="item.tooltip">
                        <mat-icon>print</mat-icon>
                      </button>
                    }
                    @case ('custom') {
                      <button
                        class="ic-action-custom"
                        [ngClass]="item.customColor ? item.customColor : 'bg-ica-third'"
                        (click)="emitEventCustom(element, item.valueEvent)"
                        [matTooltip]="item.tooltip">
                        <mat-icon>{{ item.icon }}</mat-icon>
                      </button>
                    }
                  }
                }
              }
            </div>
          </td>
        } @else {
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="conditionColorTd(element)"
            [class.text-right]="tableColumn.position === 'right'"
            (click)="emitGoToDetails(element)">
            <div>
              @switch (tableColumn.typeElement) {
                @case ('image') {
                  <div>
                    <img class="w-28 p-4 md:w-32 lg:w-48" [src]="element | dataPropertyGetter: tableColumn.dataKey" />
                  </div>
                }
                @default {
                  <div>
                    @switch (tableColumn.pipe) {
                      @case ('money') {
                        {{ element | dataPropertyGetter: tableColumn.dataKey | currency: 'MXN' : 'symbol' : '1.2-2' }}
                      }
                      @case ('datetime') {
                        {{ element | dataPropertyGetter: tableColumn.dataKey | date: 'yyyy-MM-dd hh:mm a' : 'es' }}
                      }
                      @case ('date') {
                        {{ element | dataPropertyGetter: tableColumn.dataKey | formatDateCustom: 'yyyy-MM-dd hh:mm a' }}
                      }
                      @case ('phone') {
                        {{ element | dataPropertyGetter: tableColumn.dataKey | mask: '(000) 000-0000' }}
                      }
                      @case ('pipeDaysWeek') {
                        {{ element | dataPropertyGetter: tableColumn.dataKey | pipeDaysWeek }}
                      }
                      @case ('pipeBoolean') {
                        {{ element | dataPropertyGetter: tableColumn.dataKey | pipeBoolean }}
                      }
                      @default {
                        {{ element | dataPropertyGetter: tableColumn.dataKey }}
                      }
                    }
                  </div>
                }
              }
            </div>
          </td>
        }
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr [ngClass]="color_td" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  @if (tableDataSource.filteredData.length === 0) {
    <div class="flex justify-center">
      <span class="ic-label-primary">{{ notRecord() }}</span>
    </div>
  }

  <!-- Pagination -->
  @if (isPageable) {
    <mat-paginator
      #paginator
      (page)="pageEvent($event)"
      [pageSizeOptions]="paginationSizes()"
      [pageSize]="defaultPageSize"
      [showFirstLastButtons]="false"
      showFirstLastButtons
      itemsPerPageLabel="">
    </mat-paginator>
  }
</div>
