import { Pipe, PipeTransform } from '@angular/core';
import { DIAS_SEMANA } from '@shared/constantes.global';

@Pipe({
  name: 'pipeBoolean',
  standalone: true,
})
export class BooleanPipe implements PipeTransform {
  transform(value: string | boolean): unknown {
    return value ? 'Sí' : 'No';
  }
}
