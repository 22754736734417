import { Pipe, PipeTransform } from '@angular/core';
import { DIAS_SEMANA } from '@shared/constantes.global';

@Pipe({
  name: 'pipeDaysWeek',
  standalone: true,
})
export class PipeDaysWeekPipe implements PipeTransform {
  transform(days: number[]): unknown {
    if (days === null || days === undefined) return days;

    return days.map((day: number) => DIAS_SEMANA[day]).join(', ');
  }
}
