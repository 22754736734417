import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataPropertyGetter',
  standalone: true,
})
export class DataPropertyGetterPipe implements PipeTransform {
  transform(object: any, keyName): any {
    const value = object[keyName];

    if (typeof value === 'object') return value;

    return `${value ?? ''}`;
  }
}
